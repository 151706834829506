/**
 * 导入订单
 */
import request from '@/utils/request'


/**
 * 一件代发批量导入订单
 * @param {*} data 
 * @returns 
 */
export function importOrder(data) {
  return request({
    url: '/seller/trade/impartTrade',
    method: 'post',
    headers: { 'Content-Type': 'application/json'},
    data,
    loading:false
  })
}

/**
 * 一件代发查询订单批次列表
 * @param {*} data 
 * @returns 
 */
export function getBatchList(params) {
  return request({
    url: '/seller/trade/getBatchList',
    method: 'get',
    params
  })
}

/**
 * 一件代批量取消
 * @param {*} params 
 * @returns 
 */
export function batchCancelTrade (params) {
  return request({
    url: '/seller/trade/batchCancelTrade',
    method: 'put',
    params
  })
}